import { faFeed, faPerson, faTachometer } from "@fortawesome/free-solid-svg-icons";
const storedUser = JSON.parse(localStorage.getItem('user'));

const userMenus = [
  {
    label: "Dashboard",
    path: "/user/dashboard",
    icon: faTachometer,
  },
  {
    label: "User",
  },
  {
    label: "All Details",
    path: "/user/details",
    icon: faPerson,
  },
  {
    label: "Feedback",
    path: "/user/feedback",
    icon: faFeed,
  },
  {
    label: "Gym Specific",
  },
  {
    label: "Gym Page",
    path: storedUser ? `/gym/${storedUser.gymOwnerUsername}` : "/gym/not-assigned", 
    icon: faPerson,
  },
];

export default userMenus;
