import { faLeaf, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import initMenus from "../../data/menus.js";
import "./sidebar.css";
import SidebarLogo from "./SidebarLogo.jsx";
import MenuList from "./MenuList.jsx";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth.js";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import userMenus from "../../data/userMenus";
import ownerMenus from "../../data/ownerMenus.js";
import axios from "axios";

function Sidebar({ ...props }) {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [owner] = useState(ownerMenus);
  const [displayMenu, setDisplayMenu] = useState([]);
  const base_url = process.env.REACT_APP_ADMIN_API_URL;
  useEffect(() => {
    setDisplayMenu(owner);
  }, [owner]);
  const handleLogout = () => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          token: `${accessToken}`,
        },
      };
      axios
        .post(base_url + "api/admin/logout", userId,axiosConfig)
        .then((response) => {
          localStorage.clear();
          navigate("/auth/login");
        })
        .catch((error) => {
          console.error("Logout failed:", error);
        });
    }
  };

  return (
    <>
      <aside
        id="sidebar"
        className={`sidebarWrapper md:translate-x-0 -translate-x-full md:z-0 z-50 no-scrollbar ${props.className}`}
      >
        {/* Sidebar wrapper */}
        <div className="md:w-64 border-r-2 border-gray-100 h-full flex-col flex flex-shrink-0">
          {/* Logo */}
          <SidebarLogo toggle={props.toggle} icon={faLeaf} text="SyncWell" />

          {/* Search Menu */}
          {/* <SidebarSearch
            clearSearch={clearSearch}
            handleChange={handleChange}
            scButton={scButton}
            search={search}
          /> */}

          {/* Menu */}
          <MenuList menus={displayMenu} toggle={props.toggle} />

          {/* Profile */}
          <div className="pt-2 border-t border-gray-300">
            <div className="py-2 px-4">
              {/* Logout Button */}
              <button
                className="py-2 px-4 border border-emerald-500 bg-emerald-600 w-full rounded-full text-gray-200 hover:bg-emerald-600 hover:border-emerald-600 justify-end text-sm"
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faSignOut}></FontAwesomeIcon> Logout
              </button>
            </div>
          </div>
        </div>
      </aside>

      {props.className === "mobile" && (
        <div
          id="overlaySidebar"
          onClick={props.toggle}
          className="hidden w-full h-screen bg-black z-10 inset-0 opacity-60"
        >
          <div></div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
