import { faPerson } from "@fortawesome/free-solid-svg-icons";

const ownerMenus = [
  {
    label: "New Members",
    path: "/approval",
    icon: faPerson,
  },
];

export default ownerMenus;
