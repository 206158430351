import React from "react";
import "../components/LandingPage/styles/styles.css";
import { Navigate} from "react-router-dom";
import Navbar from "../components/LandingPage/components/Navbar";
import HeroBanner from "../components/LandingPage/components/HeroBanner";
import Features from "../components/LandingPage/components/Features";
import Services from "../components/LandingPage/components/Services";
import Pricing from "../components/LandingPage/components/Pricing";
import Footer from "../components/LandingPage/components/Footer";
import { useSelector } from "react-redux";

function LandingPage() {
  const { user: currentUser } = useSelector((state) => state.auth);
  if (currentUser) {
    const roles = currentUser.roles;
      if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_MOD")) {
        return <Navigate to="/admin/dashboard" />;
      } else {
        return <Navigate to="/user/dashboard" />;
      }
  }
  return (
    <>
    <Navbar />
    <main>
      <HeroBanner />
      <Features />
      <Services />
      <Pricing />
    </main>
    <Footer />
  </>
  );
}

export default LandingPage;