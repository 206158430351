import React from "react";
import Datatables from "../../components/Datatables/Table";
import TableCell from "../../components/Datatables/TableCell";

function ApproveTable({ loading, dataHeader, data, handleStatus }) {
  return (
    <Datatables loading={loading} dataHeader={dataHeader}>
      {data?.map((row, index) => (
        <tr
          key={index}
          className="bg-white border md:border-b block md:table-row rounded-md shadow-md md:rounded-none md:shadow-none mb-5"
        >
          <TableCell dataLabel="Name" showLabel={true}>
            <span className="font-medium text-sm text-gray-900">
              {row.name}
            </span>
          </TableCell>
          <TableCell dataLabel="Email" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.email}</p>
          </TableCell>
          <TableCell dataLabel="Gym Name" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.gym_name}</p>
          </TableCell>
          <TableCell dataLabel="Gym Address" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
              {row.gym_address}
            </p>
          </TableCell>
          <TableCell dataLabel="Pincode" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">{row.pincode}</p>
          </TableCell>
          <TableCell dataLabel="Status" showLabel={true}>
            <p className="font-normal text-sm text-gray-500">
              <span
                key={index}
                className="rounded-full py-1 px-3 text-xs font-semibold bg-emerald-200 text-green-900"
              >
                {row.status}
              </span>
            </p>
          </TableCell>
          <TableCell dataLabel="Action" showLabel={true}>
            {row.status === "PENDING" && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleStatus(row._id);
                }}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm"
              >
                Approve
              </button>
            )}
          </TableCell>
        </tr>
      ))}
    </Datatables>
  );
}

export default ApproveTable;
