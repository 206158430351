import React from "react";
import "../styles/footerLanding.css";

function Footer() {
  return (
    <footer id="contact" className="bg-emerald-500">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer-text__title">SyncWell</div>
            {/* <div className="footer-text__body">
            One stop for your all gym related work!
            </div> */}
          </div>
          <div className="col">
            <div className="footer-text__title">Quick links</div>
            <ul className="footer-list">
              <li>
                <a href="#features">Features</a>
              </li>
              <li>
                <a href="#home">Services</a>
              </li>
              <li>
                <a href="#pricing">Pricing</a>
              </li>
              <li>
                <a href="#download">Download</a>
              </li>
            </ul>
          </div>
          {/* <div className="col-30">
            <div className="footer-text__title">Links</div>
            <div className="footer-text__body">
            <ul className="footer-list">
              <li>
              <NavLink to="/auth/login">Login</NavLink>
              </li>
              <li>
              <NavLink to="/auth/register">Register</NavLink>
              </li>
            </ul>
            </div>
            <div className="footer-input">
              <input type="text" name="email" placeholder="Email id" />
              <div className="footer-input__icon">
                <ion-icon name="paper-plane-outline" />
              </div>
            </div>
          </div> */}
        </div>
        <div className=" text-center">© SyncWell</div>
      </div>
    </footer>
  );
}

export default Footer;
