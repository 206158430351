import React from "react";
import { Route, Routes } from "react-router-dom";

import AuthLayout from "./components/Layout/AuthLayout";
import GuestLayout from "./components/Layout/GuestLayout";
import Login from "./pages/auth/Login";
import RegisterIndex from "./pages/auth/Register";
import LandingPage from "./pages/LandingPage";
import Approve from "./pages/owner/Approve";
function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />}></Route>
      <Route path="/" element={<AuthLayout />}>
        <Route path="/approve" element={<Approve />}></Route>
      </Route>
      <Route path="/auth" element={<GuestLayout />}>
        <Route path="/auth/login" element={<Login />}></Route>
        <Route path="/auth/register" element={<RegisterIndex />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
