import React from "react";

function Services() {
  return (
    <section id="services" className="bg-emerald-500">
      <div className="section-text">
        <div className="section-text__title-centered">
          How SyncWell can help you!
        </div>
        <div className="service-cards">
          <div className="service-card">
            <div className="service-card__icon">
              <img src=".\images\user.png"  alt="user friendly icon"/>
            </div>
            <div className="service-card__text-container">
              <div className="section-text__title-small">Easy Usage</div>
              <div className="section-text__desc">
                Most optimized and user friendly experience you'll get.
              </div>
            </div>
          </div>
          <div className="service-card active">
            <div className="service-card__icon">
            <img src=".\images\monitor.png"  alt="monitor icon"/>
            </div>
            <div className="service-card__text-container">
              <div className="section-text__title-small">Monitor and Manage</div>
              <div className="section-text__desc">
                One stop to monitor and manage all your gym needs.
              </div>
            </div>
          </div>

          <div className="service-card">
            <div className="service-card__icon">
            <img src=".\images\discount.png"  alt="discount icon"/>
            </div>
            <div className="service-card__text-container">
              <div className="section-text__title-small">Discounts</div>
              <div className="section-text__desc">
                Special discounts for our long term users.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
