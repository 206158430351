import React, { useState, useEffect } from "react";
import ApproveTable from "./ApproveTable";
import axios from "axios";
import { useOutletContext } from "react-router-dom";
import Navbar from "../../components/Navbar/Index";

function Approve() {
  const API_URL = process.env.REACT_APP_ADMIN_API_URL;

  const [sidebarToggle] = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const accessToken = localStorage.getItem("token");
  const dataHeader = [
    {
      key: "name",
      label: "Name",
    },
    {
      key: "email",
      label: "Email",
    },
    {
      key: "gymName",
      label: "Gym Name",
    },
    {
      key: "gymAddress",
      label: "Gym Address",
    },
    {
      key: "pincode",
      label: "Pincode",
    },
    {
      key: "status",
      label: "Status",
    },
    {
      key: "action",
      label: "Action",
    },
  ];

  useEffect(() => {
    if (accessToken) {
      const axiosConfig = {
        headers: {
          token: `${accessToken}`,
        },
      };
      axios
        .get(
          API_URL + `api/admin/signup-requests?pageNo=${currentPage}&pageSize=10`,
          axiosConfig
        )
        .then((response) => {
          setData(response.data.responseData.data); 
          setLoading(false); 
          setTotalPages(response.data.responseData.totalPages);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Handle the error and set loading to false
        });
    } else {
      console.error("Access token not found in localStorage");
      setLoading(false); // Set loading to false to indicate an error
    }
  }, [API_URL, accessToken, currentPage]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const handleStatus = (requestId) => {
    console.log(requestId);
    if (accessToken) {
      const axiosConfig = {
        headers: {
          token: `${accessToken}`,
        },
      };
      axios
        .get(
          API_URL + `api/admin/approve?requestId=${requestId}`,
          axiosConfig
        )
        .then((response) => {
            axios
            .get(
              API_URL + `api/admin/signup-requests?pageNo=${currentPage}&pageSize=10`,
              axiosConfig
            )
            .then((response) => {
              setData(response.data.responseData.data); // Update the data state with the fetched data
              setLoading(false); // Set loading to false once data is fetched
              setTotalPages(response.data.responseData.totalPages);
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
              setLoading(false); // Handle the error and set loading to false
            });
        })
        .catch((error) => {
          console.error("Error changing status:", error);
          // Handle error scenarios, such as displaying an error message
        });
    } else {
      console.error("Access token not found in localStorage");
      // Handle the case when the access token is missing
    }
  };
  
  return (
    <>
      <main className="h-full">
        <Navbar toggle={sidebarToggle} />
        <div className="mainCard">
          <div className="border w-full border-gray-200 bg-white py-4 px-6 rounded-md">
            <ApproveTable
              loading={loading}
              dataHeader={dataHeader}
              data={data}
              handleStatus={handleStatus}
            />

            <div className="flex justify-center mt-2">
              <button onClick={handlePreviousPage} disabled={currentPage === 1} className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm">
                Previous Page
              </button>
              <p style={{ margin: "0 10px" }}>
                {currentPage} / {totalPages}
              </p>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="bg-emerald-600 text-gray-100 px-3 py-2 rounded-lg shadow-lg text-sm">
                Next Page
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Approve;
