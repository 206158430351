import React from "react";
import { Row, Container } from "react-bootstrap";

function Features() {
  return (
    <section id="features">
      <Container>
        <Row>
          <div className="section-image-features col-30">
            <img src="./images/fitness-gym-girl-nb.jpg" width="200" alt="features" />
          </div>
          <div className="section-text col-12">
            <div className="section-text__title">Features</div>

            <div className="row">
              <div className="feature-box col-50">
                <div className="section-text__title-small">Easy to Manage Members</div>
                <div className="section-text__desc">
                  SyncWell helps you in managing your gym members and monitor their membership plan and diet plan.
                </div>
              </div>

              <div className="feature-box col-50">
                <div className="section-text__title-small">
                  Various Diet Plans
                </div>
                <div className="section-text__desc">
                  You can create your gym specific diet plans and add those with you members according to their needs.
                </div>
              </div>
            </div>

            <div className="row">
              <div className="feature-box col-50">
                <div className="section-text__title-small">
                  Various Membership Plans
                </div>
                <div className="section-text__desc">
                  You can create membership plans for you gym and select them for the members during their enrolment.
                </div>
              </div>

              <div className="feature-box col-50">
                <div className="section-text__title-small">Personalised Website</div>
                <div className="section-text__desc">
                SyncWell creates personalised website also for your gym by just filling some details.
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Features;
