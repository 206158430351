import React from 'react';

const SignupDoneModal = ({ isOpen, onConfirm ,msg}) => {
  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'}`}>
      <div className="fixed inset-0 bg-black opacity-40"></div>
      <div className="relative bg-white p-6 rounded-lg shadow-lg">
        <p>{msg}</p>
        <div className="mt-4 flex justify-end">
          <button className="px-4 py-2 bg-emerald-500 text-white rounded-md " onClick={onConfirm}>
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupDoneModal;
